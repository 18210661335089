<template>
  <el-col :span="5" :offset="1">
    <el-form-item :label="$t('service.image_type')">
      <el-cascader
        :options="casOptions"
        :cascader="cascader"
        :props="casprops"
        v-model="originCascader"
        @change="cascaderChange"
        clearable
      ></el-cascader>
    </el-form-item>
  </el-col>
</template>

<script>
export default {
  props: {
    casOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    cascader: {
      type: Array,
      default: function () {
        return [];
      },
    },
    rowId: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      casprops: { multiple: true, checkStrictly: true },
      originCascader: this.cascader,
      casSelected: this.cascader,
    };
  },
  methods: {
    cascaderChange(val) {
      let changeFlag = false;
      let newCas = null;
      if (this.casSelected.length == 0) {
        this.casSelected = val;
      } else {
        this.originCascader.forEach((item) => {
          if (item[0] != this.originCascader[0][0]) {
            changeFlag = true;
          }
        });
      }
      if (changeFlag) {
        newCas = this.originCascader.filter((item) => {
          return item[0] != this.casSelected[0][0];
        });
        this.originCascader = newCas;
        this.casSelected = newCas;
      }
      console.log("subtype newCas", this.originCascader);
      this.$emit("selectCascader", {
        rowId: this.rowId,
        casData: this.originCascader,
      });
    },
  },
};
</script>

<style lang="less"></style>
