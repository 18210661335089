<template>
  <div class="application-edit">
    <div class>
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="text-dark" @click="$router.back(-1)">
              <strong>
                <i class="fa fa-chevron-left mr5"></i>
                {{ $t("back") }}
              </strong>
            </a>
          </li>
          <li class="breadcrumb-item text-grey" aria-current="page">
            {{ $t("nav.service") }}
          </li>
          <li v-if="!id" class="breadcrumb-item text-dark" aria-current="page">
            {{ $t("service.create") }}
          </li>
          <li v-if="id" class="breadcrumb-item text-dark" aria-current="page">
            {{ $t("service.edit") }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="application-edit-content">
      <el-form
        :model="formData"
        :rules="rules"
        ref="editForm"
        size="small"
        label-position="top"
      >
        <div v-if="!id" class="content-title">{{ $t("service.create") }}</div>
        <div v-if="id" class="content-title">{{ $t("service.edit") }}</div>
        <div class="section">
          <div class="section-title clearfix">
            <span class="fl circle">01</span>
            <span class="fl text">{{ $t("service.base_info") }}</span>
          </div>
          <div class="section-wrapper">
            <el-row>
              <el-col :span="7">
                <el-form-item :label="$t('service.name')" prop="app_name">
                  <el-input
                    v-model="formData.app_name"
                    maxlength="14"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('service.profile')"
                  prop="introduction"
                >
                  <el-input v-model="formData.introduction"></el-input>
                </el-form-item>
                <el-form-item :label="$t('service.desc')" prop="description">
                  <el-input
                    v-model="formData.description"
                    type="textarea"
                    :rows="3"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7" :offset="2">
                <div class="avatar-title">{{ $t("service.app_icon") }}</div>
                <el-upload
                  class="avatar-uploader"
                  action
                  :show-file-list="false"
                  :http-request="uploadAvatar"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="upload-tip" v-if="id">
                  {{ $t("service.change_icon") }}
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="section">
          <div class="section-title clearfix">
            <span class="fl circle">02</span>
            <span class="fl text">{{ $t("service.input") }}</span>
          </div>
          <div class="section-wrapper">
            <el-row>
              <el-col :span="5">
                <el-form-item
                  :label="$t('analyse.body_part')"
                  prop="body_part_examined"
                >
                  <el-select
                    v-model="formData.body_part_examined"
                    multiple
                    filterable
                    placeholder=" "
                  >
                    <el-option
                      v-for="item in bodyPartsList"
                      :key="item.id"
                      :label="item.body_part_examined"
                      :value="item.body_part_examined"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="row-title">1.Primary Input</div>
            <el-row>
              <el-col :span="5">
                <el-form-item
                  :label="$t('service.param_name')"
                  prop="key_title"
                >
                  <el-input v-model="formData.key_title"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="Key" prop="key_name">
                  <el-input v-model="formData.key_name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item :label="$t('service.image_type')" prop=" ">
                  <el-cascader
                    :options="casOptions"
                    :props="props"
                    v-model="defaultModality"
                    @change="defaultCascaderChange"
                    clearable
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item
                  :label="$t('service.data_format')"
                  prop="file_type"
                >
                  <el-select
                    v-model="formData.file_type"
                    filterable
                    placeholder=" "
                  >
                    <el-option label="Dicom" value="Dicom"></el-option>
                    <el-option label="Nifti" value="Nifti"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-for="(typeitem, index) in subtypes" :key="typeitem.rowId">
              <div class="row-title">{{ index + 2 }}.Input {{ index + 1 }}</div>
              <el-row>
                <el-col :span="5">
                  <el-form-item :label="$t('service.param_name')" prop=" ">
                    <el-input v-model="typeitem.key_title"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="Key" prop=" ">
                    <el-input v-model="typeitem.key_name"></el-input>
                  </el-form-item>
                </el-col>
                <modality-cascader
                  :casOptions="casOptions"
                  :cascader="typeitem.cascader"
                  :rowId="typeitem.rowId"
                  v-model="typeitem.cascader"
                  @selectCascader="selectCascader"
                ></modality-cascader>
                <!-- <el-col :span="5" :offset="2">
                  <el-form-item :label="$t('service.image_type')">
                    <el-cascader
                    :options="casOptions"
                    :props="props"
                    :cascader="typeitem.cascader"
                    v-model="typeitem.cascader"
                    collapse-tags></el-cascader>
                  </el-form-item>
                </el-col> -->
                <el-col :span="5" :offset="1">
                  <el-form-item :label="$t('service.data_format')" prop=" ">
                    <el-select
                      v-model="typeitem.file_type"
                      filterable
                      placeholder=" "
                    >
                      <el-option label="Dicom" value="Dicom"></el-option>
                      <el-option label="Nifti" value="Nifti"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="1">
                  <span class="delete-modal">
                    <i
                      class="el-icon-remove-outline"
                      @click="romoveModality(typeitem.rowId)"
                    ></i>
                  </span>
                </el-col>
              </el-row>
            </div>
            <div class="button-box">
              <div class="add-btn" @click="addModality">
                <i class="el-icon-plus"></i>
                <span class="ml5">{{ $t("service.add_input") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title clearfix">
            <span class="fl circle">03</span>
            <span class="fl text">{{ $t("service.set_param") }}</span>
          </div>
          <div class="section-wrapper">
            <div class="param-item">
              <el-row>
                <el-col :span="7">
                  <el-form-item
                    :label="'1.' + $t('service.app_path')"
                    prop="type"
                  >
                    <el-radio-group v-model="formData.type" style="width: 100%">
                      <div style="margin-bottom: 20px">
                        <el-radio label="docker"
                          >Docker Image Name
                          (docker_image_name:version)</el-radio
                        >
                        <el-input ref="dockerInput" v-model="docker"></el-input>
                      </div>
                      <div>
                        <el-radio label="path">{{
                          $t("service.call_path")
                        }}</el-radio>
                        <el-input ref="pathInput" v-model="path"></el-input>
                      </div>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="7" :offset="1">
                  <div class="custom-label">
                    <span class="label-text"
                      >{{
                        $t("service.custom_path")
                      }}(/local/path1/:/mount/path1;/local/path2:/mount/path2/)</span
                    >
                  </div>
                  <el-form-item label=" " prop=" ">
                    <el-input v-model="formData.type_path"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-form-item :label="'1.' + $t('service.app_path')" prop="type">
                <el-radio-group v-model="formData.type" style="width: 100%;">
                  <el-row>
                    <el-col :span="7">
                      <el-radio label="docker">Docker Image Name</el-radio>
                      <el-input ref="dockerInput" v-model="docker"></el-input>
                    </el-col>
                    <el-col :span="7" :offset="2">
                      <el-radio label="path">{{ $t("service.call_path") }}</el-radio>
                      <el-input ref="pathInput" v-model="path"></el-input>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item> -->
              <!-- <el-form-item label="自定义文件挂载路径" prop=" ">
                <el-row>
                  <el-col :span="7">
                    <el-input v-model="formData.type_path"></el-input>
                  </el-col>
                </el-row>
              </el-form-item> -->
            </div>
            <div class="param-item">
              <div class="item-title">2.{{ $t("service.system") }}</div>
              <div>
                <el-checkbox v-model="formData.system_realname">{{
                  $t("name")
                }}</el-checkbox>
                <el-checkbox v-model="formData.system_gender">{{
                  $t("gender")
                }}</el-checkbox>
                <el-checkbox v-model="formData.system_age">{{
                  $t("age")
                }}</el-checkbox>
              </div>
            </div>
            <div class="param-item">
              <div class="item-title">3.{{ $t("service.custom_param") }}</div>
              <div class="select-box clearfix">
                <div class="fl add-param mr10">
                  {{ $t("service.add_param") }}
                </div>
                <div class="fl" style="width: 200px">
                  <el-select
                    v-model="custom_type"
                    @change="selectAddParamType"
                    placeholder=" "
                    size="small"
                  >
                    <el-option label="text" value="text"></el-option>
                    <el-option
                      label="single-select"
                      value="single-select"
                    ></el-option>
                    <el-option
                      label="mult-select"
                      value="mult-select"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-row>
                <el-col :span="18">
                  <div class="table-content">
                    <el-table
                      ref="multipleTable"
                      :data="custom_parameters"
                      :empty-text="$t('no_data')"
                      border
                      style="width: 100%"
                    >
                      <el-table-column
                        prop="param_name"
                        :label="$t('service.param_name')"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="param_key"
                        label="Key"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="student_count"
                        label="value"
                        align="center"
                      >
                        <template v-slot="scope">
                          <div v-if="scope.row.param_value.length > 0">
                            <span>{{ scope.row.param_value.toString() }}</span>
                          </div>
                          <span v-else>{{ scope.row.param_value }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="param_type"
                        :label="$t('service.param_type')"
                        align="center"
                      ></el-table-column>

                      <el-table-column
                        prop="param_visible"
                        align="center"
                        :label="$t('status')"
                      >
                        <template v-slot="scope">
                          <span v-if="scope.row.param_visible">{{
                            $t("service.user_visible")
                          }}</span>
                          <span v-else>{{ $t("service.user_invisible") }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('operate')" align="center">
                        <template v-slot="scope">
                          <span
                            class="cursor text-primary mr10"
                            @click="showEditPopover(scope.row)"
                            >{{ $t("doctor.modify") }}</span
                          >
                          <span
                            class="cursor text-danger"
                            @click="handleDeleteParam(scope.row)"
                            >{{ $t("delete") }}</span
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- 4. cpu gpu-->
            <div class="param-item">
              <el-form-item :label="'4.' + $t('service.core')" prop="core_type">
                <el-radio-group
                  v-model="formData.core_type"
                  style="width: 100%"
                >
                  <div style="margin-top: 10px">
                    <el-radio label="cpu">{{
                      $t("service.core_cpu")
                    }}</el-radio>
                    <el-radio label="gpu">{{
                      $t("service.core_gpu")
                    }}</el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title clearfix">
            <span class="fl circle">04</span>
            <span class="fl text">{{ $t("service.computer_resource") }}</span>
          </div>
          <div class="section-wrapper">
            <el-row>
              <el-col :span="7">
                <el-form-item :label="$t('service.cpu')" prop="cpu">
                  <el-select v-model="formData.cpu" placeholder=" ">
                    <el-option label="2" value="2"></el-option>
                    <el-option label="4" value="4"></el-option>
                    <el-option label="8" value="8"></el-option>
                    <el-option label="16" value="16"></el-option>
                    <el-option label="32" value="32"></el-option>
                    <el-option label="64" value="64"></el-option>
                    <el-option label="128" value="128"></el-option>
                    <el-option label="256" value="256"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7" :offset="2">
                <el-form-item :label="$t('service.memory')" prop="ram">
                  <el-select v-model="formData.ram" placeholder=" ">
                    <el-option label="8" value="8"></el-option>
                    <el-option label="16" value="16"></el-option>
                    <el-option label="32" value="32"></el-option>
                    <el-option label="64" value="64"></el-option>
                    <el-option label="128" value="128"></el-option>
                    <el-option label="256" value="256"></el-option>
                    <el-option label="512" value="512"></el-option>
                    <el-option label="1024" value="1024"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="section">
          <div class="section-title clearfix">
            <span class="fl circle">05</span>
            <span class="fl text">{{ $t("service.result_data") }}</span>
          </div>
          <div class="section-wrapper download-section">
            <div class="item-title">1.{{ $t("service.result_title") }}</div>
            <el-row>
              <el-col :span="7">
                <label for="" class="el-form-item__label">
                  <el-checkbox
                    :label="$t('service.download_result')"
                    v-model="formData.result"
                  ></el-checkbox
                ></label>
                <el-input
                  ref="resultInput"
                  size="small"
                  v-model="download_type.result"
                ></el-input>
                <div style="margin-top: 10px">
                  {{ $t("service.download_text") }}
                </div>
              </el-col>
              <el-col :span="7" :offset="2">
                <label for="" class="el-form-item__label">
                  <el-checkbox
                    :label="$t('service.download_statistical')"
                    v-model="formData.statistic"
                  ></el-checkbox>
                </label>
                <el-input
                  ref="statisticInput"
                  size="small"
                  v-model="download_type.statistic"
                ></el-input>
              </el-col>
            </el-row>
          </div>

          <div class="section-wrapper download-section">
            <div class="item-title">2.{{ $t("service.result_view") }}</div>
            <label for="" class="el-form-item__label">
              <el-checkbox v-model="formData.use_voi_editor">{{
                $t("voi_edit")
              }}</el-checkbox></label
            >

            <el-row v-show="formData.use_voi_editor">
              <el-form-item label="Label Image Path" prop="raw_image">
                <el-input
                  ref="labelImageInput"
                  v-model="formData.voi_label_image"
                ></el-input>
              </el-form-item>
              <el-form-item label="Base Image Path" prop="raw_image">
                <el-input
                  ref="baseImageInput"
                  v-model="formData.voi_base_image"
                ></el-input>
              </el-form-item>

              <el-form-item label="Lookup Table JSON" prop="lookup_table">
                <el-input
                  ref="lookupTableInput"
                  v-model="formData.voi_lookup_table"
                  type="textarea"
                  :rows="13"
                  placeholder='
                        例如：
                              {
                                "1": {
                                  "color": "#FFC013",
                                  "name": "SFG_L"
                                },
                                "10": {
                                  "color": "#FFBB51",
                                  "name": "MFG_DPFC_R"
                                }
                              }'
                ></el-input>
              </el-form-item>
            </el-row>
          </div>
        </div>

        <div class="section">
          <div class="section-title clearfix">
            <span class="fl circle">06</span>
            <span class="fl text">{{ $t("analyse.viewer") }}</span>
          </div>
          <div class="section-wrapper viewer-section">
            <el-row>
              <el-col :span="7">
                <el-form-item prop="viewer_name">
                  <el-select v-model="formData.viewer_name" placeholder=" ">
                    <el-option :label="$t('service.none')" value=""></el-option>
                    <el-option
                      :label="$t('service.generic')"
                      value="hypoviewer"
                    ></el-option>
                    <el-option label="t1viewer" value="t1viewer"></el-option>
                    <el-option label="ptviewer" value="ptviewer"></el-option>
                    <el-option label="dtiviewer" value="dtiviewer"></el-option>
                    <el-option
                      label="flairviewer"
                      value="flairviewer"
                    ></el-option>
                    <el-option
                      label="multimodalityviewer"
                      value="multimodalityviewer"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="button-wrapper">
          <button
            type="button"
            class="btn btn-primary"
            @click.prevent="submitApplication('editForm')"
          >
            {{ $t("save") }}
          </button>
        </div>
      </el-form>
    </div>
    <text-model
      :showPopover="showTextModel"
      :paramObj="paramObj"
      :popoverTitle="popoverTitle"
      @closePopover="closePopover"
      @submitTextParams="submitParams"
    ></text-model>
    <single-model
      :showPopover="showSingleModel"
      :paramObj="paramObj"
      :options="options"
      :parameters="current_parameters"
      :popoverTitle="popoverTitle"
      @closePopover="closePopover"
      @submitSingleParams="submitParams"
    ></single-model>
    <mult-model
      :showPopover="showMultModel"
      :paramObj="paramObj"
      :options="options"
      :popoverTitle="popoverTitle"
      @closePopover="closePopover"
      @submitMultParams="submitParams"
    ></mult-model>
  </div>
</template>

<script>
import {
  ruleRequest,
  getApplicationDetail,
  addApplication,
  editApplication,
  getUploadParams,
  getModalityCasRequest,
} from "../../api/api";
import { Loading } from "element-ui";
import { uniqid, getsuffix } from "../../utils/utils";
import textModel from "../../components/application/textModel";
import singleModel from "../../components/application/singleModel";
import multModel from "../../components/application/multModel";
import modalityCascader from "../../components/application/modalityCascader";

export default {
  components: {
    textModel,
    singleModel,
    multModel,
    modalityCascader,
  },
  data() {
    return {
      formData: {
        app_name: "",
        introduction: "",
        description: "",
        body_part_examined: "",
        type: "",
        type_path: "",
        system_gender: false,
        system_age: false,
        system_realname: false,
        cpu: "",
        ram: "",
        result: false,
        statistic: false,
        key_name: "",
        file_type: "",
        use_voi_editor: true, //默认false
        voi_label_image: "",
        voi_base_image: "",
        voi_lookup_table:
          '{"1": {"color": "#FFC013","name": "SFG_L" }, "10": { "color": "#FFBB51", "name": "MFG_DPFC_R"  }}',
        core_type: "cpu", //cpu or gpu
        viewer_name: "",
      },
      defaultModality: [],
      subtypes: [],
      docker: "",
      path: "",
      custom_type: [],
      custom_parameters: [],
      download_type: {
        result: "",
        statistic: "",
      },
      bodyPartsList: [],
      modalityList: [],
      seriesList: [],
      imageUrl: "",
      appIconUrl: "",
      paramObj: {},
      showTextModel: false,
      showSingleModel: false,
      showMultModel: false,
      popoverTitle: "",
      options: [],
      casOptions: [],
      id: this.$route.query.id || "",
      props: { multiple: true, checkStrictly: true },
      casSelected: [],
      current_parameters: {}, //当前点击要修改的参数对像
    };
  },
  computed: {
    rules() {
      return {
        app_name: [{ required: true, message: " ", trigger: "change" }],
        //body_part_examined: [{ required: true, message: " ", trigger: "blur" }],
        key_name: [{ required: true, message: " ", trigger: "change" }],
        key_title: [{ required: true, message: " ", trigger: "change" }],
        file_type: [{ required: true, message: " ", trigger: "change" }],
        type: [{ required: true, message: " ", trigger: "change" }],
        type_link: [{ required: true, message: " ", trigger: "change" }],
        cpu: [{ required: true, message: " ", trigger: "change" }],
        ram: [{ required: true, message: " ", trigger: "change" }],
      };
    },
  },
  created() {
    // this.getSeriesList();
    // this.getModalityList();
    this.getModalityCas();
    this.getBodyPartsList();
    !!this.id && this.getApplicationObj();
  },
  methods: {
    //获取检查部位
    async getBodyPartsList() {
      let params = {
        request: "body_part_examined",
        userid: this.$store.state.userid,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.bodyPartsList = res.data ? res.data : [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    //获取序列
    async getSeriesList(params) {
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.seriesList = res.data ? res.data : [];
        } else {
          this.seriesList = [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    //获取模态/序列级联
    async getModalityCas() {
      let params = {
        request: "modality_series",
      };
      try {
        let res = await getModalityCasRequest(params);
        if (res.status === 200) {
          if (res.data && res.data.length) {
            let data = res.data;
            data.forEach((d) => {
              let child = [{ label: "Only 4D", value: "only4d" }];
              if (d.children && d.children.length) {
                d.children.forEach((c) => {
                  c.children = child;
                });
              } else {
                d.children = child;
              }
            });

            this.casOptions = data;
          } else {
            this.casOptions = [];
          }
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    //编辑时获取原服务详情
    async getModalityList() {
      let params = {
        request: "modality_list",
        userid: this.$store.state.userid,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.modalityList = res.data ? res.data : [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    async getApplicationObj() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let _self = this;
      let params = { id: this.id };
      try {
        let res = await getApplicationDetail(params);
        if (res.status === 200) {
          let application = res.data;
          let voi_lookup_table = application.voi_lookup_table;
          if (typeof voi_lookup_table == "object") {
            voi_lookup_table = JSON.stringify(voi_lookup_table);
          }
          this.formData = {
            app_name: application.app_name || "",
            introduction: application.introduction || "",
            description: application.description || "",
            body_part_examined: application.body_part_examined || "",
            modality: application.modality || "",
            series: application.series || "",
            type: application.type || "",
            type_path: application.type_path || "",
            system_gender: application.system_gender || "",
            system_age: application.system_age || "",
            system_realname: application.system_realname || "",
            cpu: application.cpu || "",
            ram: application.ram || "",
            result: application.result || false,
            statistic: application.statistic || false,
            key_name: application.key_name || "",
            key_title: application.key_title || "",
            file_type: application.file_type || "",
            use_voi_editor: application.use_voi_editor || false,
            voi_label_image: application.voi_label_image || "",
            voi_base_image: application.voi_base_image || "",
            voi_lookup_table: voi_lookup_table || "",
            core_type: application.core_type || "cpu",
            viewer_name: application.viewer_name || "",
          };
          this.appIconUrl = application.appicon || "";
          this.imageUrl = application.appicon_url || "";
          this.docker =
            (application.type === "docker" && application.type_link) || "";
          this.path =
            (application.type === "path" && application.type_link) || "";

          this.custom_parameters = application.custom_parameters.map(function (
            item
          ) {
            return {
              param_id: _self.randomString(),
              param_name: item.param_name,
              param_key: item.param_key,
              param_value: item.param_value,
              param_type: item.param_type,
              param_visible: item.param_visible, //自定义参数默认是否可见
            };
          });

          this.download_type = { ...application.download_type };

          if (!application.cascader) {
            if (application.series.length > 0 && application.modality) {
              this.defaultModality = application.series.map((item) => {
                return [application.modality, item];
              });
            } else if (application.modality && application.series.length == 0) {
              this.defaultModality = [[application.modality]];
            }
          } else {
            this.defaultModality = application.cascader;
          }

          this.casSelected = this.defaultModality;

          if (application.subtypes.length > 0) {
            let sub_types = application.subtypes;
            sub_types.forEach(function (item) {
              if (!item.cascader) {
                if (item.series.length > 0 && item.modality) {
                  item.cascader = item.series.map((seria) => {
                    return [item.modality, seria];
                  });
                } else if (item.series.length == 0 && item.modality) {
                  item.cascader = [[item.modality]];
                }
              }
              _self.subtypes.push({
                cascader: item.cascader,
                rowId: _self.randomString(),
                key_name: item.key_name,
                key_title: item.key_title || "",
                file_type: item.file_type,
                series: item.series,
              });
            });
          }
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    // selectedModality(val) {
    //   let params = {
    //     request: "series_list",
    //     userid: this.$store.state.userid,
    //     modality: val
    //   };
    //   this.formData.series = "";
    //   this.getSeriesList(params);
    // },
    //图片上传验证，不大于1M且只能为PNG或者JPG格式的图片
    beforeAvatarUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 <= 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 或者 PNG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传图标大小不能超过 2MB!");
      }
      return isJPG && isLt1M;
    },
    //上传图片成功后将返回的图片地址赋值给this.imgUrl
    uploadAvatar(file) {
      let _self = this;
      const filename =
        uniqid() + "_" + _self.$store.state.userid + getsuffix(file.file.name);
      let uploadParams = getUploadParams({
        userid: this.$store.state.userid,
        upload_type: "appicon",
      });

      uploadParams.then((res) => {
        let getUploadJson = this.uploadXhr(res, file.file, filename);

        getUploadJson.then(
          function (response) {
            if (response.status === 200) {
              _self.imageUrl = response.data.appicon_url;
              _self.appIconUrl = response.data.appicon;
            }
          },
          function (err) {
            console.log(err);
          }
        );
      });
    },
    //图片上传到服务器
    uploadXhr: function (uploadRes, file, filename) {
      let promise = new Promise(function (resolve, reject) {
        let handler = function () {
          if (this.status === 200) {
            //console.log(this.response)
            resolve(JSON.parse(this.response));
          } else {
            reject(new Error(this.statusText));
          }
        };
        file.thumb = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("success_action_status", "200");
        formData.append("OSSAccessKeyId", uploadRes.accessid);
        formData.append("policy", uploadRes.policy);
        formData.append("Signature", uploadRes.signature);
        formData.append("key", uploadRes.dir);
        formData.append("userfile", file, filename);

        let client = new XMLHttpRequest();
        client.open("POST", uploadRes.host, true);
        // client.upload.onprogress = function (e) {
        //   if (e.lengthComputable) {
        //     const percentComplete = (e.loaded / e.total) * 100;
        //     if(percentComplete === 100) {
        //     }
        //   }
        // };
        client.onreadystatechange = handler;
        client.send(formData);
      });
      return promise;
    },
    //返回一个随机的字符串，作为自定义参数项的id值，便于修改和删除
    randomString() {
      var timestamp = new Date().getTime();
      let charSet =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomString = "";
      for (let i = 0; i < 8; i++) {
        let randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
      }
      return randomString + timestamp.toString();
    },

    defaultCascaderChange(val) {
      let changeFlag = false;
      let newCas = null;
      if (this.casSelected.length == 0) {
        this.casSelected = val;
      } else {
        this.defaultModality.forEach((item) => {
          if (item[0] != this.defaultModality[0][0]) {
            changeFlag = true;
          }
        });
      }
      if (changeFlag) {
        newCas = this.defaultModality.filter((item) => {
          return item[0] != this.casSelected[0][0];
        });
        this.defaultModality = newCas;
        this.casSelected = newCas;

        this.$forceUpdate();
      }
    },
    selectCascader(emitData) {
      function findCasItem(casItem) {
        return casItem.rowId === emitData.rowId;
      }
      const _index = this.subtypes.findIndex(findCasItem);
      if (_index > -1) {
        this.subtypes[_index].cascader = emitData.casData;
      }
    },
    submitApplication(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSubmit();
        } else {
          return false;
        }
      });
    },
    //提交服务应用
    async handleSubmit() {
      let _self = this;
      console.log(_self.formData);
      let type_link = "";
      let voi_lookup_table = _self.formData.voi_lookup_table; //json value

      if (_self.formData.type === "docker") {
        if (!_self.docker) {
          _self.$helper.warningMessage(_self.$t("service.notice1"));
          this.$refs.dockerInput.focus();
          return false;
        }
        if (_self.docker.indexOf(":") < 0) {
          _self.$helper.warningMessage(_self.$t("service.notice10"));
          return false;
        }
        if (
          _self.formData.type_path &&
          _self.formData.type_path.indexOf(":") < 0
        ) {
          _self.$helper.warningMessage(_self.$t("service.notice11"));
          return false;
        }
      }
      if (_self.formData.type === "path" && !_self.path) {
        _self.$helper.warningMessage(_self.$t("service.notice2"));
        this.$refs.pathInput.focus();
        return false;
      }
      let sub_types = this.subtypes;
      let paramSubTypes = [];
      if (sub_types.length > 0) {
        let filterTypes = sub_types.filter((item) => {
          return !item.key_name || !item.file_type || item.cascader.length == 0;
        });
        if (filterTypes.length > 0) {
          _self.$helper.warningMessage(_self.$t("service.notice3"));
          return false;
        }

        sub_types.forEach(function (item) {
          if (item.cascader.length > 0) {
            item.modality = item.cascader[0][0];

            let subseries = [];
            item.cascader.forEach(function (cas, casindex) {
              cas[1] && cas[1] != "only4d" && !subseries.includes(cas[1])
                ? subseries.push(cas[1])
                : "";
            });

            item.series = subseries;
          }
        });

        paramSubTypes = sub_types;
        console.log("save cur", paramSubTypes);
        // paramSubTypes = sub_types.map(function (typeItem) {
        //   return {
        //     modality: typeItem.modality,
        //     series: typeItem.series,
        //     key_name: typeItem.key_name,
        //     key_title: typeItem.key_title,
        //     file_type: typeItem.file_type,
        //   };
        // });
      }

      if (_self.defaultModality.length == 0) {
        _self.$helper.warningMessage(_self.$t("service.notice8"));
        return false;
      }
      //modify:
      let series = [];

      _self.defaultModality.forEach(function (pre, cur) {
        if (pre[1] && pre[1] != "only4d" && !series.includes(pre[1])) {
          series.push(pre[1]);
        }
      });

      // let reg = /^\/.*\/$/;
      if (_self.formData.result) {
        let resultText = _self.download_type.result.trim();
        if (!resultText) {
          _self.$helper.warningMessage(_self.$t("service.notice4"));
          this.$refs.resultInput.focus();
          return false;
        }
        // if(!reg.test(resultText)) {
        //   _self.$helper.warningMessage('下载路径必须以"/"开头和结尾');
        //   return false
        // }
      }
      if (_self.formData.statistic) {
        let statiText = _self.download_type.statistic.trim();
        if (!statiText) {
          _self.$helper.warningMessage(_self.$t("service.notice4"));
          this.$refs.statisticInput.focus();
          return false;
        }
        // if(!reg.test(statiText)) {
        //   _self.$helper.warningMessage('下载路径必须以"/"开头和结尾');
        //   return false
        // }
      }
      if (!_self.formData.result && _self.download_type.result) {
        _self.$helper.warningMessage(_self.$t("service.notice5"));
        return false;
      }
      if (!_self.formData.statistic && _self.download_type.statistic) {
        _self.$helper.warningMessage(_self.$t("service.notice6"));
        return false;
      }

      if (
        _self.formData.use_voi_editor &&
        (_self.formData.voi_label_image == "" ||
          _self.formData.voi_base_image == "" ||
          voi_lookup_table == "")
      ) {
        _self.$helper.warningMessage(_self.$t("service.notice12"));
        return false;
      }

      if (!_self.formData.use_voi_editor) {
        _self.formData.voi_lookup_table = "";
      }

      // if (voi_lookup_table.length) {
      //   //voi_lookup_table = JSON.stringify(JSON.parse(voi_lookup_table));

      //   _self.formData.voi_lookup_table = JSON.stringify(
      //     voi_lookup_table,
      //     null,
      //     4
      //   );
      // }

      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (_self.formData.type === "docker") {
        type_link = _self.docker;
      } else if (_self.formData.type === "path") {
        type_link = _self.path;
      }
      let paramArrayVisible = [];
      let paramArrayUnVisible = [];
      let paramsArray = [];
      _self.custom_parameters.map(function (item) {
        let paobj = {
          param_name: item.param_name,
          param_key: item.param_key,
          param_value: item.param_value,
          param_type: item.param_type,
          param_visible: item.param_visible,
        };

        item.param_visible
          ? paramArrayVisible.push(paobj)
          : paramArrayUnVisible.push(paobj);
      });

      //console.log("保存1：", _self.custom_parameters);
      paramsArray = [...paramArrayVisible, ...paramArrayUnVisible];

      //console.log("保存2：", paramsArray);

      let params = {
        ..._self.formData,
        //voi_lookup_table: voi_lookup_table,
        appicon: _self.appIconUrl,
        type_link: type_link,
        custom_parameters: paramsArray,
        download_type: _self.download_type,
        subtypes: paramSubTypes,
        modality: _self.defaultModality[0][0],
        series: series,
        cascader: _self.defaultModality,
      };

      //console.log("submit params", params);

      if (_self.id) {
        //根据是否传入query值，判断为编辑还是新增
        params.id = _self.id;
        try {
          let res = await editApplication(params);
          if (res.status === 200) {
            _self.$helper.successNotify(res.message);
          } else {
            this.$helper.errNotify(res.message);
          }
          loadingInstance.close();
        } catch (error) {
          loadingInstance.close();
          this.$helper.errNotify(error.message);
        }
      } else {
        try {
          let res = await addApplication(params);
          if (res.status === 200) {
            _self.$helper.successNotify(res.message);

            // _self.$helper.successNotify(
            //   res.message,
            //   function () {
            //     _self.$router.push("/application");
            //   },
            //   1000
            // );
          } else {
            this.$helper.errNotify(res.message);
          }
          loadingInstance.close();
        } catch (error) {
          loadingInstance.close();
          this.$helper.errNotify(error.message);
        }
      }
    },
    selectAddParamType(type) {
      this.paramObj = { param_visible: true };
      this.options = [];
      if (type === "text") {
        this.popoverTitle = this.$t("service.add_text_param"); //"添加Text参数";
        this.showTextModel = true;
      } else if (type === "single-select") {
        this.popoverTitle = this.$t("service.add_single_param"); //"添加single-select参数";
        this.showSingleModel = true;
      } else if (type === "mult-select") {
        this.popoverTitle = this.$t("service.add_mult_param"); //"添加Mult-select参数";
        this.showMultModel = true;
      }
      this.custom_type = "";
    },
    handleDeleteParam(param) {
      function checkParam(paramItem) {
        return paramItem.param_id === param.param_id;
      }
      let _index = this.custom_parameters.findIndex(checkParam);
      if (_index > -1) {
        this.custom_parameters.splice(_index, 1);
      }
    },
    //修改自定义参数项
    showEditPopover(param) {
      //console.log(param);
      if (param.param_type === "text") {
        this.paramObj = {
          param_id: param.param_id,
          param_key: param.param_key,
          param_name: param.param_name,
          default_val: param.param_value,
          param_visible: param.param_visible,
          param_type: "text",
        };
        this.popoverTitle = this.$t("service.edit_text_param"); //"编辑Text参数";
        this.showTextModel = true;
      } else if (param.param_type === "single-select") {
        this.paramObj = {
          param_id: param.param_id,
          param_key: param.param_key,
          param_name: param.param_name,
          param_value: param.param_value,
          default_val: param.param_value[0],
          param_visible: param.param_visible,
          param_type: "single-select",
        };

        this.getOptions(param);

        this.popoverTitle = this.$t("service.edit_single_param"); //"编辑single-select参数";
        this.showSingleModel = true;
      } else if (param.param_type === "mult-select") {
        this.paramObj = {
          param_id: param.param_id,
          param_key: param.param_key,
          param_name: param.param_name,
          param_value: param.param_value,
          default_val: param.param_value[0],
          param_visible: param.param_visible,
          param_type: "mult-select",
        };

        this.getOptions(param);
        this.popoverTitle = this.$t("service.edit_mult_param"); //"编辑Mult-select参数";
        this.showMultModel = true;
      }
      // console.log("编辑Mult-select参数 paramObj", param.param_id);
      // console.log("编辑Mult-select参数 getOptions", this.options);
    },
    getOptions(paramObj) {
      let option = [];
      if (paramObj.param_value && paramObj.param_value.length > 1) {
        for (var i = 1; i < paramObj.param_value.length; i++) {
          let keyText = "Options value " + i;
          let item = paramObj.param_value[i];
          option.push({
            key: keyText,
            value: item,
          });
        }
      }
      this.options = option;
    },
    //找出选项的第一项作为默认参数
    // getOptions(paramObj) {
    //   if (paramObj.param_value && paramObj.param_value.length > 1) {
    //     let optionsArr = paramObj.param_value.splice(
    //       1,
    //       paramObj.param_value.length
    //     );
    //     this.options = paramObj.param_value.map(function (item, index) {
    //       if (index == 0) {
    //         let keyText = "Options value" + (index + 1);
    //         return {
    //           key: keyText,
    //           value: item,
    //         };
    //       }
    //     });
    //   }
    // },
    //确认自定义参数
    submitParams(param) {
      function checkParam(paramItem) {
        return paramItem.param_id === param.param_id;
      }
      let _index = this.custom_parameters.findIndex(checkParam);
      if (_index < 0) {
        this.custom_parameters.push(param);
      } else {
        this.custom_parameters.splice(_index, 1, param);
      }

      //console.log("保存：", this.custom_parameters);
      this.closePopover();
    },
    closePopover() {
      //this.paramObj = {};
      //this.options = [];
      //this.custom_type = "";
      this.showTextModel = false;
      this.showSingleModel = false;
      this.showMultModel = false;
    },
    //添加其它输入数据类型
    addModality() {
      let randomStr = this.randomString();
      this.subtypes.push({
        cascader: [],
        key_title: "",
        key_name: "",
        file_type: "",
        rowId: randomStr,
        series: [],
      });
    },
    romoveModality(rowId) {
      function checkModality(item) {
        return item.rowId === rowId;
      }
      let _index = this.subtypes.findIndex(checkModality);
      if (_index > -1) {
        this.subtypes.splice(_index, 1);
      }
    },
  },
};
</script>

<style lang="less">
.application-edit-content {
  padding: 20px 40px;
  background: #fff;
  border: 1px solid #dee2e6;
  .content-title {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dee2e6;
    font-size: 20px;
    color: #212528;
  }
  .section-title {
    padding: 5px 15px;
    background: #f2f7fa;
    font-size: 14px;
    .circle {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background: #000;
      color: #fff;
      line-height: 20px;
      text-align: center;
      border-radius: 100%;
    }
    .text {
      height: 20px;
      line-height: 20px;
    }
  }
  .section-wrapper {
    padding: 15px 0 10px 30px;
    &.download-section {
      padding-bottom: 18px;
    }
    .row-title {
      margin-bottom: 5px;
      font-size: 14px;
    }
    .button-box {
      margin-bottom: 10px;
      .add-btn {
        border: 1px solid #027bff;
        border-radius: 4px;
        width: 120px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        color: #027bff;
        cursor: pointer;
      }
    }
    .el-form-item--small .el-form-item__label {
      height: 20px;
      line-height: 20px;
    }
    .el-form-item__label {
      padding-bottom: 0;
    }
    .el-select {
      width: 100%;
    }
    .param-item {
      padding-top: 10px;
      padding-bottom: 18px;
      border-bottom: 1px solid #dee2e6;
      &:first-child {
        padding-top: 0;
        padding-bottom: 0;
        .el-form-item__label {
          margin-bottom: 10px;
        }
        .el-col:last-child .el-form-item__label {
          margin-bottom: 0;
        }
        // .el-radio{
        //   margin-bottom: 5px;
        // }
      }
      .item-title {
        margin-bottom: 10px;
        font-size: 14px;
      }
      .add-param {
        line-height: 32px;
        color: #027bff;
      }
      .table-content {
        padding-top: 10px;
      }
    }
  }
  .button-wrapper {
    margin-top: 30px;
    .btn-default {
      margin-right: 20px;
      border: 1px solid #ced4da;
    }
  }
  .avatar-title {
    height: 20px;
    line-height: 20px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .upload-tip {
    margin-bottom: 15px;
    font-size: 12px;
    color: #999;
  }
  .delete-modal {
    display: inline-block;
    padding-top: 20px;
    padding-left: 10px;
    line-height: 33px;
    font-size: 24px;
    i {
      cursor: pointer;
      color: #dc3545;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .custom-label {
    position: relative;
    height: 25px;
    width: 100%;
    font-size: 14px;
    .label-text {
      position: absolute;
      left: 0;
      bottom: -20px;
    }
  }
}
</style>
