<template>
  <div class="brain-popover single-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ popoverTitle }}</span>
      </div>
      <div class="popover-body">
        <div class="form-content">
          <el-form
            :model="paramObj"
            :rules="rules"
            ref="modelForm"
            label-width="140px"
          >
            <el-form-item :label="$t('service.param_name')" prop="param_name">
              <el-input v-model="paramObj.param_name"></el-input>
            </el-form-item>
            <el-form-item label="Key：" prop="param_key">
              <el-input v-model="paramObj['param_key']"></el-input>
            </el-form-item>
            <el-form-item label="Default value" prop="default_val">
              <el-input v-model="paramObj['default_val']"></el-input>
            </el-form-item>
            <el-form-item
              v-for="item in options"
              :key="item.key"
              :label="item.key"
            >
              <el-input v-model="item.value"></el-input>
            </el-form-item>

            <el-form-item :label="$t('status')" prop="param_visible">
              <el-radio-group
                v-model="paramObj.param_visible"
                style="width: 100%"
              >
                <el-radio :label="true">{{
                  $t("service.user_visible")
                }}</el-radio>
                <el-radio :label="false">{{
                  $t("service.user_invisible")
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <span class="add-option" @click="addOption">+</span>
        </div>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitParam('modelForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paramObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
    popoverTitle: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: [],
    },
  },
  computed: {
    rules() {
      return {
        param_name: [{ required: true, message: " ", trigger: "change" }],
        param_key: [{ required: true, message: " ", trigger: "change" }],
        default_val: [{ required: true, message: " ", trigger: "change" }],
      };
    },
  },
  methods: {
    addOption() {
      let keyText = "Options value " + (this.options.length + 1);
      this.options.push({ key: keyText, value: "" });
    },
    submitParam(formName) {
      let _self = this;
      _self.$refs[formName].validate((valid) => {
        if (valid) {
          // let option_vals = _self.options.map(function (item) {
          //   return item.value;
          // });

          let option_vals = [];

          for (let index = 0; index < _self.options.length; index++) {
            const element = _self.options[index];
            if (element.value.trim() == "") {
              _self.options.splice(index, 1);
              continue;
            }
            option_vals.push(element.value);
          }
          let param_value = [_self.paramObj.default_val].concat(option_vals);
          let param = {
            param_name: _self.paramObj.param_name,
            param_key: _self.paramObj["param_key"],
            param_value: param_value,
            param_type: "single-select",
            param_id: _self.paramObj["param_id"] || _self.randomString(),
            param_visible: _self.paramObj.param_visible,
          };
          _self.$emit("submitSingleParams", param);
        } else {
          return false;
        }
      });
    },
    randomString() {
      var timestamp = new Date().getTime();
      let charSet =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomString = "";
      for (let i = 0; i < 8; i++) {
        let randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
      }
      return randomString + timestamp.toString();
    },
    closePopover() {
      this.$emit("closePopover");
    },
  },
};
</script>

<style lang="less">
.single-popover {
  .popover-body {
    padding-right: 0;
    width: 600px;
    max-height: 400px;
    overflow-y: auto;
    .form-content {
      position: relative;
      padding-right: 80px;
      .add-option {
        display: block;
        position: absolute;
        right: 45px;
        bottom: 72px;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 18px;
        border-radius: 50%;
        border: 1px solid #027bff;
        color: #027bff;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .el-select {
    width: 100%;
  }
  label {
    margin-bottom: 0;
  }
}
</style>
